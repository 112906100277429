<template>
  <v-app>
    <v-app-bar app color="admin" dense dark>
      <v-app-bar-nav-icon @click.stop="drawer = !drawer"></v-app-bar-nav-icon>

      <v-toolbar-title>
        <router-link to="/admin" class="text-decoration-none white--text"
          >Risk Score Admin</router-link
        ></v-toolbar-title
      >

      <v-spacer></v-spacer>

      <!-- <amplify-sign-out v-if="signedIn"></amplify-sign-out> -->
      <v-btn text @click="signOut($event)">Sign Out</v-btn>
    </v-app-bar>

    <v-navigation-drawer v-model="drawer" app dark temporary>
      <v-list nav dense dark>
        <v-list-item-group v-model="group" active-class="text--accent-4">
          <v-list-item
            v-for="link in links"
            :key="link.path"
            exact
            :to="{ path: link.path }"
          >
            <v-list-item-title>{{ link.text }}</v-list-item-title>
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </v-navigation-drawer>

    <v-main>
      <v-container fluid class="px-8 pb-16">
        <slot />
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
import { Auth } from "aws-amplify";

export default {
  data: () => ({
    drawer: false,
    group: null,
    signedIn: false,
    links: [
      {
        text: "Admin Dashboard",
        path: "/admin",
      },
      {
        text: "Manage Advisors",
        path: "/admin/advisors",
      },
      {
        text: "Manage Prospects",
        path: "/admin/prospects",
      },
      {
        text: "Reporting",
        path: "/admin/reporting",
      },
      {
        text: "Importing",
        path: "/admin/import",
      },
      {
        text: "Report Editor (deprecated)",
        path: "/admin/report-editor",
      },
      {
        text: "Report Templates",
        path: "/admin/report-templates",
      },
    ],
  }),
  created() {
    console.log("admin created layout");
  },
  methods: {
    signOut(e) {
      e.preventDefault();
      Auth.signOut()
        .then(() => {
          this.signedIn = false;
          this.$router.push("/auth");
        })
        .catch(() => (this.signedIn = false));
    },
  },
};
</script>

<style lang="scss">
.theme--light.v-data-table .v-toolbar .v-data-footer {
  border: none;
  padding-right: 0;
}

.v-data-table .v-toolbar__content {
  padding-right: 0;
}
</style>
